// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// $offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Include functions first
@import "bootstrap/scss/functions";

// Customize some defaults
// $body-color: #333;
// $body-bg: #f8f9fa;
// $border-radius: .4rem;
// $success: #7952b3;

// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";


//
// Custom styles
//


body {
  background-color: #f8f9fa;
}
.main-header{
  padding: 20px;
  background-color: rgb(255, 255, 255);
  display: flex;
}

.main-footer{
  color: rgb(214, 214, 214);
  display: flex;
  padding: 20px;
}

.content{
  // padding: 20px;
  
}

.vysledek{
  color: #121212;
}
.ramecek {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 12px 12px 0 12px;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 16px;
}
.version {
  font-size: 0.9em;
  color: rgb(102, 102, 102);
  margin-top: 1.6em;
  margin-left: 0.5em;
}

@media (max-width: 576px) {
  .mobile-fixed {
      z-index: auto;
      position: fixed;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: white;
      width: 100%;
      font-size: 1.3em;
      text-align: center;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 11px 0px;
      padding: 0.8em 1em 1.3em 1em;
      // border-top: 1px solid rgb(206, 212, 218);
  }
}
@media (min-width: 576px) {
  .mobile-fixed {
      display: none;
  }
}
.main-footer {
  z-index: -1;
}